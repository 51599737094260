<template>
  <BaseElSelect
    v-model="syncModel"
    filterable
    remote
    :multiple="multiple"
    clearable
    v-bind="$attrs"
    reserve-keyword
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    style="height: 100%;"
    @change="$emit('change')"
    @clear="getDataList"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="(item, index) in dataList"
      :key="`${index} - ${item.name}`"
      :label="item.name"
      :value="getValue(item)"
    />
  </BaseElSelect>
</template>
<script>
import { useShop } from '@/use/shop'
import { useVModel } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

export default {
  name: 'BaseSearch',
  props: {
    value: [String, Number, Array, Object],
    objKey: String,
    multiple: Boolean,
    api: {
      type: Function,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const dataList = ref([])
    const { shopId } = useShop()
    const syncModel = useVModel(props, 'value', emit)
    const getDataList = async (nameSearch) => {
      emit('clear')
      const [res, err] = await props.api({
        shopId: shopId.value,
        ...props.filter,
        name: nameSearch || undefined,
        limit: 100,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      dataList.value = res
    }

    const remoteSearch = async (query) => {
      query = query.trim()
      if (!query) dataList.value = []

      try {
        loading.value = true
        setTimeout(async () => {
          await getDataList(query)
          loading.value = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await getDataList()
    })

    return { syncModel, loading, dataList, remoteSearch, getDataList }
  },

  methods: {
    getValue (item) {
      if (this.objKey) return item[this.objKey]
      else return item
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-select  {
  height: 100% !important;
}
::v-deep .el-input  {
  height: 100% !important;
}

::v-deep .el-select__tags {
@apply flex-nowrap;
}

::v-deep .el-select__tags .el-tag {
  @apply max-w-[120px];
}
</style>
